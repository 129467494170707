<template>
  <!--  考试信息-->
  <div class="exercisesType">
    <div style="display: flex; justify-content: space-between">
      <div class="menu_select">
        <h4 style="padding: 10px">模块</h4>
        <a-menu style="width: 100%" mode="inline" v-model="menu">
          <a-menu-item
            v-for="(item, index) in menuList"
            :key="index"
            @click="leftChange(item.id)"
            >{{ item.name }}</a-menu-item
          >
        </a-menu>
      </div>
      <div class="InfoTable">
        <div class="navTop">
          <div>
            年份：
            <a-date-picker
              mode="year"
              v-model="year"
              placeholder="请选择年份"
              format="YYYY"
              style="width: 200px"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"
            />
          </div>

          <div>
            标题：
            <a-input
              style="width: 200px"
              placeholder="请输入"
              v-model="title"
            ></a-input>
          </div>
          <div>
            <a-radio-group default-value="0">
              状态：
              <a-radio-button value="0" @click="changRadio('0')">
                已发布</a-radio-button
              >
              <a-radio-button value="1" @click="changRadio('1')">
                已置顶
              </a-radio-button>
              <a-radio-button value="2" @click="changRadio('2')">
                未发布</a-radio-button
              >
            </a-radio-group>
          </div>
          <div>
            <a-button type="primary" @click="search()">查询</a-button>
            <a-button style="margin-left: 10px" @click="clear()">重置</a-button>
          </div>
        </div>
        <div class="nav">
          <a-button type="primary" style="margin: 15px 15px" @click="add"
            >新增</a-button
          >
          <a-table
            :columns="columns"
            :data-source="messageList"
            :rowKey="(record) => record.id"
            :pagination="false"
            style="margin: 15px"
          >
            <span slot="sort" slot-scope="text, record, index">
              {{ (pageNo - 1) * pageSum + index + 1 }}
            </span>
            <span slot="operation" slot-scope="text, record, index">
              <a-popconfirm
                title="确定下架?"
                ok-text="是"
                cancel-text="否"
                @confirm="removeMessage(record.id)"
                @cancel="cancel"
              >
                <a style="margin-right: 10px" v-if="record.isRelease == 1"
                  >下架</a
                >
              </a-popconfirm>
              <a-popconfirm
                title="确定上架?"
                ok-text="是"
                cancel-text="否"
                @confirm="removeMessage1(record.id)"
                @cancel="cancel"
              >
                <a style="margin-right: 10px" v-if="record.isRelease == 0"
                  >上架</a
                >
              </a-popconfirm>

              <a
                v-if="record.isTop !== 1"
                style="margin-right: 10px"
                @click="topMessage(record.id, record)"
                :disabled="record.isRelease == 0"
                >置顶</a
              >
              <a
                v-if="record.isTop == 1"
                style="margin-right: 10px"
                @click="topMessage(record.id, record)"
                :disabled="record.isRelease == 0"
                >取消置顶</a
              >
              <a style="margin-right: 10px" @click="edit(record)">编辑</a>

              <!-- <a-popconfirm
                title="确定推荐?"
                ok-text="是"
                cancel-text="否"
                @confirm="recommendMessage(record.id)"
                @cancel="cancel"
              > -->
              <a @click="recommendMessage(record)" style="margin-right: 10px" :disabled="record.isRelease == 0">推广</a>
              <!-- </a-popconfirm> -->
            </span>
          </a-table>
        </div>
        <div
          style="
            margin-top: 28px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
            style="
              font-size: medium;
              color: #929292;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ total }}条记录 第 {{ pageNo }} /
            {{ Math.ceil(total / pageSum) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pageSum"
            :total="total"
            v-model="pageNo"
            @change="pageChange"
            @showSizeChange="pageChange"
          />
        </div>
      </div>
    </div>

    <!-- 新增 -->
    <a-drawer
      title="新增"
      :width="500"
      :visible="addVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="addCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="addForm"
        ref="ruleForm_add"
        :rules="addRules"
      >
        <a-form-model-item label="标题" prop="title">
          <a-input placeholder="请输入" v-model="addForm.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="副标题" prop="subtitle">
          <a-input placeholder="请输入" v-model="addForm.subtitle"></a-input>
        </a-form-model-item>
        <a-form-model-item label="发表日期" prop="issueDate">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            style="width: 320px"
            placeholder="请选择"
            @change="onTimeChange"
            v-model="addForm.issueDate"
          />
        </a-form-model-item>
        <a-form-model-item label="内容" prop="text">
          <div
            v-if="addVisible == true"
            style="border: 1px solid #ccc; min-height: 500px; width: 350px"
          >
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: hidden"
              v-model="addForm.text"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
          <!-- <a-input placeholder="请输入" v-model="addForm.text"></a-input> -->
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="addCancel"> 取消 </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="addOK">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!-- 编辑 -->
    <a-drawer
      title="编辑"
      :width="500"
      :visible="editVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="editCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="editForm"
        ref="ruleForm_edit"
        :rules="editRules"
      >
        <a-form-model-item label="标题" prop="title">
          <a-input placeholder="请输入" v-model="editForm.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="副标题" prop="subtitle">
          <a-input placeholder="请输入" v-model="editForm.subtitle"></a-input>
        </a-form-model-item>
        <a-form-model-item label="发表日期" prop="issueDate">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            style="width: 320px"
            placeholder="请选择"
            v-model="editForm.issueDate"
          />
        </a-form-model-item>
        <a-form-model-item label="内容" prop="text">
          <!-- <a-input placeholder="请输入" v-model="editForm.text"></a-input> -->
          <div
            v-if="editVisible == true"
            style="border: 1px solid #ccc; min-height: 500px; width: 350px"
          >
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: hidden"
              v-model="editForm.text"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="editCancel"> 取消 </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="editOK">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!-- 推荐 -->
    <a-drawer
      title="推广"
      :width="500"
      :visible="promotionsVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="promotionsClose"
    >
      <div style="margin:20px">
        <a-table :pagination="false" :columns="appColumns" :data-source="appData" :row-key="record => record.code" bordered>
          <span slot="is_open" slot-scope="text, record">  
            <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="EXAMRecommend(record)" default-checked />
            <!-- 需要后端写一个查询 -->
            <!-- <a-switch v-if="record.title == '社区首页'" v-model="record.is_open" checked-children="是" un-checked-children="否" @click="communityListSwitch(record.is_open,record)" default-checked />
            <a-switch v-else v-model="record.is_open" checked-children="是" un-checked-children="否" @click="AppListSwitch(record.is_open,record)" default-checked /> -->
          </span>
        </a-table>
      </div>
      <div style="margin:20px">
         <a-table :row-key="record => record.id" :columns="uniAppColumns" :data-source="uniAppData" bordered>
           <span slot="is_open" slot-scope="text, record">
              <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record)" default-checked />
            </span>
         </a-table>
       </div>
    </a-drawer>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import axios from "axios";
import moment from "moment";
import { current_date } from "@/utils/currentDate";
import {postminaRecommend,getminaRecommend,delminaRecommend,getExanRecommend,delExanRecommend} from "@/service/Recruiting";
import {
  getMoKuaiData,
  getExamMessage,
  postExamMessage,
  putExamMessage,
  putExamMessageTop,
  editMessage,
  postRecommendMessage,
} from "@/service/titleExam_api";
const editorConfig = {
  // JS 语法
  MENU_CONF: {},
};
const signature = "";
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF["uploadImage"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData();
    formData.append("file", file);
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios
      .post(
        "https://yun-new-admin-api.yunicu.com/api/admin/common/update",
        formData
      )
      .then((res) => {
        if (res.data.code === 0) {
          insertFn(res.data.data.url); // 最后插入图片
        }
      });
  },
};
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF["uploadVideo"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios
      .get("https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign")
      .then((res) => {
        if (res.data.code === 0) {
          const getSignature = () => {
            return res.data.data.signature;
          };
          const tcVod = new TcVod({
            getSignature: getSignature, //上传签名的函数
          });
          const uploader = tcVod.upload({
            mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
          });
          uploader.done().then((res) => {
            insertFn(res.video.url); // 进行视频处理：转码和截图
          });
        }
      });
  },
};
export default {
  name: "exercisesType",
  components: { Editor, Toolbar },
  data() {
    return {
      uniAppColumns:[
        {
          title: '小程序',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      uniAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      appData:[
        {
          id:1,
          title:'职称考试首页',
          is_open:false
        }
      ],
      appColumns:[
        {
          title: '模块',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      promotionsVisible:false,
      editor: null,
      toolbarConfig: {},
      mode: "default", // or 'simple'
      editorConfig,
      menuList: [],
      messageList: [],
      year: moment(current_date()).format("YYYY"),
      yearShowOne: false,
      title: "",
      isRelease: 1, //发布
      isTop: "", //置顶
      state: 0,
      pageNo: 1,
      pageSum: 10,
      total: 0,
      id: "",
      menu: [0],
      addVisible: false,
      labelColLive: { span: 5 },
      wrapperCol: { span: 17 },
      addForm: {},
      editForm: {},
      editVisible: false,
      columns: [
        {
          title: "序号",

          width: "5%",
          scopedSlots: { customRender: "sort" },
        },
        {
          title: "标题",
          dataIndex: "title",
          width: "15%",
        },
        {
          title: "副标题",
          dataIndex: "subtitle",
          width: "18%",
        },
        {
          title: "发表日期",
          dataIndex: "issueDate",
          width: "10%",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          width: "10%",
        },
        {
          title: "操作",
          scopedSlots: { customRender: "operation" },
          width: "17%",
        },
      ],
      addRules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        subtitle: [{ required: true, message: "请输入", trigger: "blur" }],
        issueDate: [{ required: true, message: "请选择", trigger: "change" }],
        text: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      promotionId:'',
      editRules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        subtitle: [{ required: true, message: "请输入", trigger: "blur" }],
        issueDate: [{ required: true, message: "请选择", trigger: "change" }],
        text: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      examTypeId:'',
    };
  },
  created() {},
  mounted() {
    this.getMenu();
    // this.load();
  },
  methods: {
    // async minaRecommend(){
    //   const data = {
    //     contentId:this.promotionId,
    //     contentType:'EXAM',
    //     subContentType:"EXAM_INFO",
    //   }
    //   const response = await postminaRecommend(data)
    //   if(response.code === 0){
    //     this.$message.success("操作成功！")
    //   }else {
    //     this.$message.warning(response.message)
    //   }
    // },
    async EXAMRecommend(record){
      if(record.is_open === true){
        let data = {
          contentId: this.promotionId, //考点精选或者考试信息的id
          examTypeId: this.id, //职称模块id
          contentType: "message", //考点精选：choiceness  考试信息  ： message
        };
        const res = await postRecommendMessage(data);
        if (res.code == 0) {
          this.$message.success("操作成功");
          // this.leftChange(this.id);
        } else {
          this.$message.warn(res.message);
        }
      }else{
        const res = await delExanRecommend(this.promotionId,'message',this.examTypeId);
        if (res.code == 0) {
          this.$message.success("操作成功");
          // this.leftChange(this.id);
        } else {
          this.$message.warn(res.message);
        }
      }
    },
    async minaRecommend(record){
      if(record.is_open === true){
        const data = {
          contentId:this.promotionId,
          contentType:'EXAM',
          subContentType:"EXAM_INFO",
        }
        const response = await postminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else{
        const data = {
          contentId:this.promotionId,
          contentType:'EXAM',
          subContentType:"EXAM_INFO",
        }
        const response = await delminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 默认加载第一个模块内容
    async load() {
      // console.log("第一个", this.menuList);
      this.id = this.menuList[0].id;
      let data = {
        examTypeId: this.menuList[0].id,
        year: this.year,
        title: this.title,
        isRelease: this.isRelease,
        isTop: this.isTop,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
      };
      console.log(data);
      const res = await getExamMessage(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    // 分页查询
    pageChange(pageNo, pageSum) {
      this.pageNo = pageNo;
      this.pageSum = pageSum;
      this.leftChange(this.id);
    },
    //弹出日历和关闭日历的回调
    openChangeOne(status) {
      //status是打开或关闭的状态
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.year = value;
      this.yearShowOne = false;
      if (this.year) {
        this.year = moment(this.year).format("YYYY");
      }
    },
    // callback(e) {
    //   let activeKey = e.target.value;
    //   switch (activeKey) {
    //     case "2": //未发布
    //       this.isRelease = 0;
    //       this.state = 2;
    //       this.getTable(this.id);
    //       break;
    //     case "1": //已置顶
    //       this.isTop = 1;
    //       this.state1;
    //       this.getTable(this.id);
    //       break;
    //     case "0": //已发布
    //       this.isRelease = 1;
    //       (this.state = 0), this.getTable(this.id);
    //       break;
    //     default:
    //       break;
    //   }
    // },
    changRadio(e) {
      switch (e) {
        case "2":
          (this.isRelease = 0), (this.isTop = ""), this.search();
          break;
        case "1":
          (this.isRelease = ""), (this.isTop = 1), this.search();
          break;
        case "0":
          this.isRelease = 1;
          this.isTop = "";
          this.search();
          break;
      }
    },
    promotionsClose(){
      this.promotionsVisible = false
      // this.appData = []
      // this.promotionId = null
      // this.getDocument()
      // this.subContentType = null
    },
    // 查询
    async search() {
      let data = {
        examTypeId: this.id,
        year: this.year,
        title: this.title,
        isRelease: this.isRelease,
        isTop: this.isTop,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
      };

      const res = await getExamMessage(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    clear() {
      (this.title = ""),
        (this.isRelease = 1),
        (this.isTop = ""),
        (this.year = moment(current_date()).format("YYYY")),
        this.leftChange(this.id);
    },
    async getMenu() {
      let data = {
        page: 1,
        pageSize: 999,
      };
      const res = await getMoKuaiData(data);
      if (res.code == 200) {
        this.menuList = res.data;
        this.load();
      }
    },
    // 点击左侧菜单获取内容
    async leftChange(id) {
      this.title = "";
      (this.year = moment(current_date()).format("YYYY")), (this.id = id);
      let data = {
        examTypeId: this.id,
        year: this.year,
        title: this.title,
        isRelease: this.isRelease,
        isTop: this.isTop,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
      };

      const res = await getExamMessage(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    add() {
      this.addVisible = true;
    },
    onTimeChange(date, dateString) {
      this.addForm.issueDate = dateString;
    },
    addCancel() {
      this.addForm = {};
      this.addVisible = false;
      this.$refs["ruleForm_add"].clearValidate(); // 只清除清除验证
    },
    async addOK() {
      if (this.addForm.text == "<p><br></p>") {
        delete this.addForm.text;
      }
      this.$refs.ruleForm_add.validate(async (valid) => {
        if (valid) {
          let data = {
            title: this.addForm.title,
            subtitle: this.addForm.subtitle,
            text: this.addForm.text,
            issueDate: this.addForm.issueDate,
            examTypeId: this.id,
          };
          const res = await postExamMessage(data);
          if (res.code == 0) {
            this.addVisible = false;
            this.addForm = {};
            (this.addForm.issueDate = ""), this.$message.success("添加成功！");
            this.leftChange(this.id);
          }
        }
      });
    },

    // 下架
    async removeMessage(id) {
      const res = await putExamMessage(id, 0);
      if (res.code == 0) {
        this.$message.success("下架成功");
        const res = putExamMessageTop(id);
        this.leftChange(this.id);
      } else {
        this.$message.warn(res.message);
      }
    },
    // 上架
    async removeMessage1(id) {
      const res = await putExamMessage(id, 1);
      if (res.code == 0) {
        this.$message.success("上架成功");
        this.leftChange(this.id);
      } else {
        this.$message.warn(res.message);
      }
    },
    cancel() {},
    // 置顶
    async topMessage(id, record) {
      const res = await putExamMessageTop(id);
      if (res.code == 0) {
        this.leftChange(this.id);
        if (record.isTop !== 1) {
          this.$message.success("置顶成功");
        } else {
          this.$message.success("取消成功");
        }
      } else {
        this.$message.warn(res.message);
      }
    },
    // 推荐
    async recommendMessage(record) {
      this.promotionsVisible = true
      this.promotionId = record.id
      this.examTypeId = record.examTypeId
      // 回显
      this.minaRecord()
      // 推荐回显
      this.ExamRecord()
    },
    //  小程序推广回显
    async minaRecord(){
      // 小程序推广回显  
      const data = {
        contentId:this.promotionId,
        contentType:'EXAM',
        subContentType:"EXAM_INFO",
      }
      const response = await getminaRecommend(data)
      if (response.code === 0) {
        if(response.data[0] && response.data[0].code === "YUN_ICU"){
           this.uniAppData[0].is_open = true
        }else{
          this.uniAppData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    // 职称考试首页推荐  
    async ExamRecord(){
      const response = await getExanRecommend(this.promotionId,'message',this.examTypeId)
      if (response.code === 0) {
        if(response.data == 1){
           this.appData[0].is_open = true
        }else{
          this.appData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    // 编辑
    edit(record) {
      this.leftChange(this.id);
      this.editForm = record;
      this.editVisible = true;
    },
    editCancel() {
      this.editForm = {};
      this.editVisible = false;
      this.$refs["ruleForm_edit"].clearValidate(); // 只清除清除验证
    },
    editOK() {
      this.$refs.ruleForm_edit.validate(async (valid) => {
        if (valid) {
          let data = {
            title: this.editForm.title,
            subtitle: this.editForm.subtitle,
            text: this.editForm.text,
            issueDate: this.editForm.issueDate,
            examTypeId: this.id,
            id: this.editForm.id,
          };
          const res = await editMessage(data);
          if (res.code == 0) {
            this.editVisible = false;
            this.editForm = {};
            this.$message.success("编辑成功！");
            this.leftChange(this.id);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.exercisesType {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .menu_select {
    background-color: white;
    width: 18%;
    min-height: 750px;
  }
  .InfoTable {
    background-color: white;
    width: 81%;
    min-height: 750px;
    .navTop {
      width: 100%;
      height: 100px;
      border-bottom: 15px solid rgba(240, 242, 245, 1);
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .nav {
      width: 100%;
    }
  }
}
</style>
